<template>
  <router-link
    v-if="to"
    :to="to"
    :class="classes"
    :style="style"
  >
    {{ label }}
  </router-link>

  <a
    v-else-if="href"
    target="_blank"
    rel="noreferrer"
    :href="href"
    :class="classes"
    :style="style"
  >{{ label }}</a>

  <button
    v-else
    type="button"
    :class="classes"
    :style="style"
    @click="onClick"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    label: {
      type: String,
      required: true
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      required: false,
      default: null
    },
    href: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    classes () {
      return {
        'storybook-button': true,
        'storybook-button--primary': this.primary,
        'storybook-button--secondary': this.secondary,
        'storybook-button--tertiary': this.tertiary
      }
    },
    style () {
      return {
        backgroundColor: this.backgroundColor
      }
    }
  },
  methods: {
    onClick: function (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
button.storybook-button,
a.storybook-button {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  transition: 250ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
  white-space: nowrap;

  &--primary {
    color: $black;
    background: $yellow-1;
    width: 170px;
    height: 40px;
    font-size: 0.875rem;
    line-height: 24px;
    padding: 7px 12px;

    &:hover {
      background: $yellow-2;
    }
  }

  &--secondary {
    color: $black;
    background: $white;
    width: 170px;
    height: 40px;
    font-size: 0.875rem;
    line-height: 24px;
    padding: 7px 12px;
    border: 1px solid $black;

    &:hover {
      background: #f2f2f2;
    }
  }

  &--tertiary {
    color: $black;
    background: #f2f2f2;
    width: auto;
    height: 40px;
    font-size: 0.875rem;
    line-height: 24px;
    padding: 7px 16px;

    &:hover {
      background: $grey-7;
    }
  }

  &:focus-visible {
    outline: 2px solid $black;
  }
}

a.storybook-button:hover {
  text-decoration: none;
  color: initial;
}
</style>
